
import { Row, Col, Button, Select, Tabs, Form, Input, Slider, InputNumber, Upload, Checkbox, message } from 'antd'
import { useState, useEffect, useRef } from "react";
import { useParams, Link } from 'react-router-dom';
import { InboxOutlined } from '@ant-design/icons';
import { Player } from 'video-react';



import Fetch_ from "../utils/fetch";
import CodeSnippet from "../components/CodeSnippet";
import LoadingPng from '../assets/images/playground/loading.png'
import LoadingGif from '../assets/images/playground/loading.gif'

import AudioPlayer from '../components/AudioPlayer';
import Playground_API from './components/Playground_API'
import Playground_Version from './components/Playground_Version'

import './styles/playground.scss'
import './styles/playground_api.scss'

import { useLoginContext } from '../context/LoginContext';


const { Option } = Select;
const { Dragger } = Upload;
const { TextArea } = Input;


const Playground = () => {
    const { isLogin, setIsLogin, login } = useLoginContext();
    const { key } = useParams();
    const [form] = Form.useForm();
    const audioRef = useRef(null);
    const videoRef = useRef(null);

    const [tabItems, setTabItems] = useState([
        {
            key: '1',
            label: 'Playground'
        },
        // {
        //     key: '2',
        //     label: 'API'
        // },
        // {
        //     key: '3',
        //     label: 'Readme'
        // },
        {
            key: '4',
            label: 'Version'
        }
    ])

    const [codeTabItems, setCodeTabItems] = useState([
        {
            key: 'Python',
            label: 'Python-H'
        },
        {
            key: 'Python-SDK',
            label: 'Python-S'
        },
        {
            key: 'JavaScript',
            label: 'JS-H'
        },
        {
            key: 'JavaScript-SDK',
            label: 'JS-S'
        },
        {
            key: 'HTTP',
            label: 'HTTP'
        }
    ])
    const [currentTab, setCurrentTab] = useState('1');
    const [activeCodeTab, setActiveCodeTab] = useState('Python');
    const [resCon, setResCon] = useState('');
    const [loading, setLoading] = useState(false);
    const [countDown, setCountDown] = useState(60);
    const [remainingHours, setRemainingHours] = useState(0);
    const [remainingMinutes, setRemainingMinutes] = useState(0);
    const [remainingSeconds, setRemainingSeconds] = useState(0);

    const [textareaVal, setTextareaVal] = useState('');
    const [dataArr, setDataArr] = useState({})
    const [initVal, setInitVal] = useState({});
    const [sliderValues, setSliderValues] = useState({});
    const [fileList, setFileList] = useState([]);

    const [currentApiKey, setCurrentApiKey] = useState('');
    const [resTask, setResTask] = useState({});
    const [currentExample, setCurrentExample] = useState({})
    const [activeModelType, setActiveModelType] = useState(1)
    const [publishStatus, setPublishStatus] = useState(1)
    const [uploadType, setUploadType] = useState('image/png');


    const [exampleCode, setExampleCode] = useState({})
    const [exampleOptions, setExampleOptions] = useState([])
    const [runCount, setRunCount] = useState('')
    const [currentLargeImg, setCurrentLargeImg] = useState('')

    const [versions, setVersions] = useState({});


    let js_sdk_code = `const AIGIC = require("aigic")

const options = {
    //Please replace with your own API key or jwt token.
    auth:"$API_KEY"
}

const aigic = new AIGIC(options)

let response = await aigic.prediction("$API_PATH",
{
    input:$INPUT
})
console.log("test",response)`

    let python_sdk_code = `from aigic import aigic
    
api_key = "$API_KEY"

aigic_instance = aigic.AIGIC(api_key)

data = {
    "input":$INPUT
}

result = aigic_instance.prediction("$API_PATH",data)

print(result)`


    const codeTabChange = (key) => {
        setActiveCodeTab(key);
    };

    const handleExample = (content, option) => {
        setCurrentExample(option)
        updateAfterSwitchExample(option)
    }

    const updateAfterSwitchExample = (example) => {
        let formInput = {}
        if (example && example.input) {
            formInput = isJSONString(example.input) ? JSON.parse(example.input) : example.input
        }
        let resProperties = dataArr.form.components.schemas.Input.properties
        const initialValues_ = Object.fromEntries(
            Object.entries(resProperties).map(([fieldName, fieldData]) => [
                fieldName,
                fieldData.default,
            ])
        );
        const initialValues = { ...initialValues_, ...formInput }
        Object.entries(resProperties).map(([fieldName, fieldData]) => {
            if (fieldData.type === 'string' && fieldData.format === 'uri') {
                let fileList = []
                if (Array.isArray(initialValues[fieldName]) && initialValues[fieldName].length > 0) {
                    fileList = [...initialValues[fieldName]]
                    fileList = fileList.map((file, index) => {
                        if (!file.url.includes('https://replicate.delivery')) {
                            return {
                                uid: 'image' + index,
                                name: file.url,
                                status: 'done',
                                url: file.url
                            }
                        }
                    })
                    // initialValues[fieldName] = fileList
                } else {
                    let fileObj = {}
                    fileObj[fieldName] = initialValues[fieldName] && !initialValues[fieldName].includes('https://replicate.delivery') ? [{
                        uid: '-1',
                        name: initialValues[fieldName],
                        status: 'done',
                        url: initialValues[fieldName],
                        type: 'image/png',
                    }] : []
                    setFileList(fileObj)
                }
            }
        })
        form.setFieldsValue(initialValues)
        setInitVal(initialValues)
        setSliderValues(initialValues)
        if (example && example.input) {
            let input = isJSONString(example.input) ? JSON.parse(example.input) : example.input
            setTextareaVal(input.prompt)
        }
        let output = isJSONString(example.output) ? JSON.parse(example.output) : example.output
        setResCon(output)
        if (audioRef.current) {
            audioRef.current.load();
        }
        if (videoRef.current) {
            videoRef.current.load();
        }

    }

    async function getData() {
        const res = await Fetch_('/admin/api/task/modelbyid', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ id: Number(key) }),
        });

        const jsonData = await res.json()
        return jsonData
    }
    const optSubmit = async (values) => {
        const transformedValues = {};

        if (publishStatus !== 1) {
            message.warning("This model isn't open yet.")
            return
        }
        if (loading) return
        setResCon('')
        setLoading(true)
        if (values.prompt) setTextareaVal(values.prompt)

        const postData = {
            input: { ...values }
        }
        queryApi(postData)
    }

    const optReset = async () => {
        setResTask(null)
        setLoading(false)
        if (exampleOptions && exampleOptions.length) {
            let example = exampleOptions[0]
            setCurrentExample(example)
            updateAfterSwitchExample(example)
        }
    }

    const queryApi = async (data) => {
        let timing = null
        try {
            if (dataArr.estimat_exe_time && dataArr.estimat_exe_time > 0) {
                let count = Number(dataArr.estimat_exe_time) + Number(dataArr.start_up_time)
                setCountDown(count)
                timing = setInterval(() => {
                    const hours = Math.floor(count / 3600);
                    const minutes = Math.floor((count % 3600) / 60);
                    const seconds = count % 60;
                    setRemainingHours(hours);
                    setRemainingMinutes(minutes);
                    setRemainingSeconds(seconds);
                    count -= 1;
                    setCountDown(count)
                    if (count === 0) {
                        clearInterval(timing)
                        setRemainingHours(0);
                        setRemainingMinutes(0);
                        setRemainingSeconds(0);
                    }
                }, 1000)
            }
            const response = await fetch('/api/v1' + dataArr['api_path'], {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'apikey': isLogin ? currentApiKey : '4oipB83LZCpkrVN3i12f38WcBUYH5MR9',
                    'async': false,
                },
                body: JSON.stringify(data),
            });

            if (response.ok) {
                if (response.status === 200) {
                    try {
                        const resultJson = await response.json();
                        const isResultTask = resultJson && resultJson.task
                        if (isResultTask && resultJson.task.is_success === false) {
                            setLoading(false)
                            setResTask({ ...resultJson.task, output: resultJson.output })
                            if (resultJson.task.exec_code == 409) {
                                message.error('Already running a prediction')
                                return
                            }
                            if (resultJson.task.api_error && resultJson.task.api_error.message) {
                                message.error(resultJson.task.api_error.message)
                            } else if (resultJson.task.task_error) {
                                message.error(resultJson.task.task_error)
                            }
                        }
                        if (resultJson && resultJson.task.is_success && resultJson.output) {
                            setResTask(null)
                            setLoading(false)
                            message.success('Your AI content has been successfully generated.')
                            if (Array.isArray(resultJson.output) && resultJson.output.length > 0) {
                                // const output = [].concat(...resultJson.output)
                                const outputArr = resultJson.output.filter(item => Array.isArray(item)).flat() || []
                                const outputArrStr = resultJson.output.filter(item => typeof (item) === 'string').flat() || []
                                setResCon(outputArrStr.length > 0 ? outputArrStr : outputArr)
                                if (audioRef.current) {
                                    audioRef.current.load();
                                }
                            }
                        }
                    } catch (error) {
                        setResTask(null)
                        if (response.url) {
                            setResCon(response.url)
                            setLoading(false)
                        } else {
                            setLoading(false)
                            message.error(response.statusText)
                        }
                    }
                }
                clearInterval(timing)
                setRemainingHours(0);
                setRemainingMinutes(0);
                setRemainingSeconds(0);
            } else {
                clearInterval(timing)
                setRemainingHours(0);
                setRemainingMinutes(0);
                setRemainingSeconds(0);
                message.error(`Request failed with status ${response.status}`)
                setResTask(null)
                setLoading(false)
            }
        } catch (error) {
            setResTask(null)
            setLoading(false)
            clearInterval(timing)
            setRemainingHours(0);
            setRemainingMinutes(0);
            setRemainingSeconds(0);
            console.error('Error posting data:', error)
        }
    }
    const handleSliderChange = (value, fieldName) => {
        setSliderValues({ ...sliderValues, [fieldName]: value }); // 更新表单项的值
        form.setFieldsValue({ [fieldName]: value }); // 更新表单的值
    };


    // 根据不同类型创建对应的表单组件
    const getFieldComponent = (fieldData, fieldName) => {
        const token = localStorage.getItem("token")
        if (fieldData.type === 'string' && fieldData.format === 'uri') {
            let obj = {}
            const props = {
                name: 'file',
                multiple: true,
                // listType: 'picture',
                maxCount: 1,
                fileList: fileList[fieldName],
                action: 'https://tmp-file.aigic.ai/api/v1/upload?expires=1800&type=' + uploadType,
                headers: {
                    Authorization: token
                },
                beforeUpload(file) {
                    setUploadType(file.type)
                },
                onChange(info) {
                    const { status } = info.file;
                    obj = { ...fileList }
                    obj[fieldName] = info.fileList
                    setFileList(obj)
                    if (status !== 'uploading') {
                        obj[fieldName] = info.fileList
                        setFileList(obj)
                    }
                    if (status === 'removed') {
                        obj[fieldName] = info.fileList
                        setFileList(obj)
                        form.setFieldValue(fieldName, '')
                    }
                    if (status === 'done') {
                        message.success(`${info.file.name} file uploaded successfully.`);
                        if (info.file.response && info.file.response.data && info.file.response.data.length) {
                            info.fileList[0].url = info.file.response.data
                            obj[fieldName] = info.fileList
                            setFileList(obj)
                            form.setFieldValue(fieldName, info.file.response.data)
                        }
                    } else if (status === 'error') {
                        message.error(`${info.file.name} file upload failed.`);
                        return
                    }
                },
                onDrop(e) {
                    console.log('Dropped files', e.dataTransfer.files);
                },
            }
            return (
                <Dragger {...props}>
                    <p className="ant-upload-drag-icon">
                        <InboxOutlined />
                    </p>
                    <p className="ant-upload-text">Drop a file or click here to upload</p>
                </Dragger>
            );
        }

        if (fieldData.allOf) {
            // 如果字段包含 allOf 引用，解析引用并获取引用的对象值
            const refField = fieldData.allOf.find(item => item.$ref);
            if (refField) {
                const refPath = refField.$ref.substring(refField.$ref.lastIndexOf('/') + 1);
                const refObj = dataArr.form.components.schemas[refPath];
                return (
                    <Select>
                        {refObj && refObj.enum && refObj.enum.length > 0 && refObj.enum.map((enumValue, index) => (
                            <Option key={index} value={enumValue}>
                                {enumValue}
                            </Option>
                        ))}
                    </Select>
                );
            }
        }
        switch (fieldData.type) {
            case 'string':
                return fieldName === 'prompt' ? <TextArea rows={3} /> : <Input />;
            case 'number':
                return <Row>
                    <Col span={6}>
                        <InputNumber
                            max={fieldData.maximum}
                            min={fieldData.minimum}
                            value={sliderValues[fieldName]}
                            onChange={value => handleSliderChange(value, fieldName)}
                        />
                    </Col>
                    <Col span={14}>
                        <Slider
                            max={fieldData.maximum}
                            min={fieldData.minimum}
                            value={sliderValues[fieldName]}
                            onChange={value => handleSliderChange(value, fieldName)}
                            step={0.01}
                        />
                    </Col>
                </Row>
            case 'integer':
                return !fieldData.maximum && !fieldData.allOf ? (
                    <InputNumber style={{ width: '100%' }} />
                ) : (<>
                    <Row>
                        <Col span={6}>
                            <InputNumber
                                max={fieldData.maximum}
                                min={fieldData.minimum}
                                value={sliderValues[fieldName]}
                                onChange={value => handleSliderChange(value, fieldName)}
                                style={{ marginRight: '10px' }}
                            />
                        </Col>
                        <Col span={14}>
                            <Slider
                                max={fieldData.maximum}
                                min={fieldData.minimum}
                                value={sliderValues[fieldName]}
                                onChange={value => handleSliderChange(value, fieldName)}
                            />
                        </Col>
                    </Row>
                </>)
            case 'boolean':
                return <Checkbox style={{width: '100%'}} checked={sliderValues[fieldName]} onChange={e => handleSliderChange(e.target.checked, fieldName)}>{fieldData.title}</Checkbox>;
            default:
                return null;
        }
    };

    const handleLogin = async () => {
        login((res, error) => {
            if (error == null) { }
        })
    }


    async function queryApiKeys() {
        try {
            const result = await Fetch_("/admin/api/app/apikeysfront", {
                method: "GET",
            })
            const res = await result.json();
            if (res.code === 200) {
                let data = res.data.data
                if (data && data.length > 0) {
                    data[0] && data[0].api_key && setCurrentApiKey(data[0].api_key)
                }
            } else if (res.code === 401) {
                // message.error(res.msg)
                setIsLogin(false)
            }
        } catch (error) {
            console.log(error)
            // setIsLogin(false)
        }
    }

    async function handleCollect(type) {
        if (!isLogin) {
            login((res, error) => {
                if (error == null) return true
            })
        }
        type = type === 0 ? 'add' : 'cancel'
        try {
            const result = await Fetch_(`/admin/api/favorite/${type}`, {
                method: "post",
                body: JSON.stringify({ task_type_id: Number(key) }),
            })
            const res = await result.json();
            if (res.code === 200) {
                message.success(type === 'add' ? 'Collection successful' : 'Cancel collection successful')
                fetchData()
            }
        } catch (error) {
            console.log(error)
        }
    }

    async function getRunCount(id) {
        try {
            const result = await Fetch_("/admin/api/task/runcount", {
                method: "POST",
                body: JSON.stringify({ id: id }),
            })
            const res = await result.json();
            if (res.code === 200) {
                let data = res.data
                if (data && data.count && data.count.length) {
                    const count = parseInt(data.count)
                    let temp = data.count
                    if (count >= 1000) {
                        temp = count / 1000 + 'K'
                    }
                    if (count >= 1000000) {
                        temp = count / 1000000 + 'M'
                    }

                    if (count >= 1000000000) {
                        temp = count / 1000000000 + 'B'
                    }

                    if (count >= 1000000000000) {
                        temp = count / 1000000000000 + 'T'
                    }

                    setRunCount(temp)
                }
            } else if (res.code === 401) {
                // message.error(res.msg)
                // setIsLogin(false)
            }
        } catch (error) {
            console.log(error)
            // setIsLogin(false)
        }
    }

    async function getVersions() {
        const res = await fetch('/admin/api/task/version/lists', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ task_type_id: Number(key) }),
        });

        const jsonData = await res.json()
        if (jsonData.code == 200 && jsonData.data) {
            setVersions(jsonData.data)
        }

        return jsonData
    }

    function removeQuotes(str) {
        // 判断字符串长度是否大于等于2，并且首尾字符是否为双引号
        if (str.length >= 2 && str.charAt(0) === '"' && str.charAt(str.length - 1) === '"') {
            // 如果首尾字符都为双引号，则去掉首尾的双引号
            return str.slice(1, -1);
        } else {
            // 否则直接返回原字符串
            return str;
        }
    }

    async function dealCode(example, data, selectApiKey) {
        if (!example || !example.input) {
            return
        }
        let host = "api.aigic.ai"
        let currentLocation = window.location.host
        let locationContents = currentLocation.split(".")
        if (locationContents.length > 2 && locationContents[0] === 'www') {
            locationContents.shift()
        }
        if (locationContents.length > 1) {
            host = "api." + locationContents.join(".")
        }
        const apikey = selectApiKey || currentApiKey
        const apipath = data.api_path
        let exampleCode = {}
        if (data.codes && data.codes.http && data.codes.http.length) {
            exampleCode.http_show = data.codes.http
            let end_point = "https://" + host
            exampleCode.http_show = exampleCode.http_show.replace("$END_POINT", end_point)
                .replace("$API_PATH", apipath)
            let input = example.input.replaceAll("  ", " ")
            exampleCode.http_show = exampleCode.http_show.replace("$INPUT", input.replace('https://replicate.delivery/', window.location.origin + '/'))
            if (apikey && apikey.length) {
                exampleCode.http_show = exampleCode.http_show.replace("$API_KEY", apikey)
            }
        }
        if (data.codes && data.codes.javascript && data.codes.javascript.length) {
            exampleCode.javascript_show = data.codes.javascript
            const end_point = "https://" + host + "/api/v1"
            exampleCode.javascript_show = exampleCode.javascript_show.replace("$END_POINT", end_point)
                .replace("$API_PATH", apipath)
            let input = example.input.replaceAll("  ", " ")
            exampleCode.javascript_show = exampleCode.javascript_show.replace("$INPUT", input.replace('https://replicate.delivery/', window.location.origin + '/'))
            if (apikey && apikey.length) {
                exampleCode.javascript_show = exampleCode.javascript_show.replace("$API_KEY", apikey)
            }
        }
        if (data.codes && data.codes.python && data.codes.python.length) {
            exampleCode.python_show = data.codes.python
            const end_point = host
            exampleCode.python_show = exampleCode.python_show.replace("$END_POINT", end_point)
                .replace("$API_PATH", "/api/v1" + apipath).replace("$INPUT", example.input.replace('https://replicate.delivery/', window.location.origin + '/'))
            if (apikey && apikey.length) {
                exampleCode.python_show = exampleCode.python_show.replace("$API_KEY", apikey)
            }
        }

        let js_sdk = js_sdk_code
        if (data.codes && data.codes.javascript_sdk && data.codes.javascript_sdk.length) {
            js_sdk = data.codes.javascript_sdk
        }
        if (js_sdk && js_sdk.length) {
            exampleCode.javascript_sdk_show = js_sdk
            exampleCode.javascript_sdk_show = exampleCode.javascript_sdk_show.replace("$API_PATH", apipath)
            let input = example.input.replaceAll("  ", " ")
            exampleCode.javascript_sdk_show = exampleCode.javascript_sdk_show.replace("$INPUT", input.replace('https://replicate.delivery/', window.location.origin + '/'))
            if (apikey && apikey.length) {
                exampleCode.javascript_sdk_show = exampleCode.javascript_sdk_show.replace("$API_KEY", apikey)
            }
        }
        let python_sdk = python_sdk_code
        if (data.codes && data.codes.python_sdk && data.codes.python_sdk.length) {
            python_sdk = data.codes.python_sdk
        }
        if (python_sdk && python_sdk.length) {
            exampleCode.python_sdk_show = python_sdk
            exampleCode.python_sdk_show = exampleCode.python_sdk_show.replace("$API_PATH", apipath)
            let input = example.input.replaceAll("  ", " ")
            exampleCode.python_sdk_show = exampleCode.python_sdk_show.replace("$INPUT", input.replace('https://replicate.delivery/', window.location.origin + '/'))
            if (apikey && apikey.length) {
                exampleCode.python_sdk_show = exampleCode.python_sdk_show.replace("$API_KEY", apikey)
            }
        }
        setExampleCode(exampleCode)
    }
    const copyApiKey = (content) => {
        navigator.clipboard.writeText(content)
            .then(() => {
                message.success('Copied');
            })
            .catch(() => {
                message.error('Copied Failed');
            });
    }

    function isJSONString(str) {
        try {
            JSON.parse(str);
            return true;
        } catch (e) {
            return false;
        }
    }

    async function fetchData() {
        const token = localStorage.getItem("token")
        if (token) {
            setIsLogin(true)
            queryApiKeys()
        }
        try {
            const data = await getData()
            if (data.code === 200 && data.data) {
                const resData = data.data
                let example = null
                if (resData && resData.examples && resData.examples.length) {
                    // let options = []
                    let options = resData.examples && resData.examples.length > 0 && resData.examples.map(item => {
                        item.value = item.title
                        item.label = 'Example-' + item.title
                        return item
                    })
                    setExampleOptions(options)
                    example = resData.examples[0]
                    if (example && example.input) {
                        let input = isJSONString(example.input) ? JSON.parse(example.input) : example.input
                        setTextareaVal(input.prompt)
                    }
                    if (example && example.output && example.output.length) {
                        let output = isJSONString(example.output) ? JSON.parse(example.output) : example.output
                        setResCon(output)
                    }
                }
                if (resData.form) {
                    let resProperties = resData.form.components.schemas.Input.properties
                    const sortedProperties = Object.entries(resProperties).sort(
                        ([, a], [, b]) => (a['x-order'] || 0) - (b['x-order'] || 0)
                    );
                    const initialValues_ = Object.fromEntries(
                        Object.entries(resProperties).map(([fieldName, fieldData]) => [
                            fieldName,
                            fieldData.default,
                        ])
                    );
                    let input = {}
                    if (example && example.input) {
                        input = isJSONString(example.input) ? JSON.parse(example.input) : example.input
                    }
                    const initialValues = { ...initialValues_, ...input }
                    resData.form.components.schemas.Input.properties = Object.fromEntries(sortedProperties);
                    Object.entries(resData.form.components.schemas.Input.properties).map(([fieldName, fieldData]) => {
                        if (fieldData.type === 'string' && fieldData.format === 'uri') {
                            let fileList = []
                            if (Array.isArray(initialValues[fieldName]) && initialValues[fieldName].length > 0) {
                                fileList = [...initialValues[fieldName]]
                                fileList = fileList.map((file, index) => {
                                    if (!file.url.includes('https://replicate.delivery')) {
                                        return {
                                            uid: 'image' + index,
                                            name: file.url,
                                            status: 'done',
                                            url: file.url
                                        }
                                    }
                                })
                                // initialValues[fieldName] = fileList
                            } else {
                                let fileObj = {}
                                fileObj[fieldName] = initialValues[fieldName] && !initialValues[fieldName].includes('https://replicate.delivery') ? [{
                                    uid: '-1',
                                    name: initialValues[fieldName],
                                    status: 'done',
                                    url: initialValues[fieldName],
                                    type: 'image/png',
                                }] : []
                                setFileList(fileObj)
                            }
                        }
                    })
                    form.setFieldsValue(initialValues)
                    setInitVal(initialValues)
                    setSliderValues(initialValues)
                }

                let estimatExeTime = resData.estimat_exe_time ? resData.estimat_exe_time : 60
                let estimat_exe_time_show = estimatExeTime + ' s'
                if (estimatExeTime > 60) {
                    let temp = estimatExeTime / 60
                    estimat_exe_time_show = Math.round(temp * 10) / 10 + ' m'
                }

                if (estimatExeTime >= 360) {
                    let temp = estimatExeTime / 360
                    estimat_exe_time_show = Math.round(temp * 10) / 10 + ' h'
                }

                if (estimatExeTime >= 86400) {
                    let temp = estimatExeTime / 86400
                    estimat_exe_time_show = Math.round(temp * 10) / 10 + ' day'
                }
                resData.estimat_exe_time_show = estimat_exe_time_show

                let startUpTime = resData.start_up_time ? resData.start_up_time : 60
                let start_up_time_show = startUpTime + ' s'
                if (startUpTime > 60) {
                    let temp = startUpTime / 60
                    start_up_time_show = Math.round(temp * 10) / 10 + ' m'
                }

                if (startUpTime >= 360) {
                    let temp = startUpTime / 360
                    start_up_time_show = Math.round(temp * 10) / 10 + ' h'
                }

                if (startUpTime >= 86400) {
                    let temp = startUpTime / 86400
                    start_up_time_show = Math.round(temp * 10) / 10 + ' day'
                }
                resData.start_up_time_show = start_up_time_show

                // console.log("fetchData", resData)
                setPublishStatus(resData.publish_status)
                setActiveModelType(resData.type)
                setCurrentExample(example)
                setDataArr(resData)
                getRunCount(resData.id)
            }

        } catch (error) {
            console.log(error)
        }
    }

    const tabChange = key => {
        setCurrentTab(key)
        setActiveCodeTab('Python')
        if (key === '4') {
            getVersions()
        }
    }

    function isBase64(str) {
        // 匹配 base64 编码的正则表达式
        const base64Regex = /^(data:)([\w\/\+]+);(charset=[\w-]+|base64),([^\n\r]+)/;
        return base64Regex.test(str);
    }

    const handleDownload = () => {
        let fileUrl = ''
        if (activeModelType === 1 || activeModelType === 6 || activeModelType === 8) { //图片
            if (resCon && Array.isArray(resCon) && resCon.length > 1) {
                fileUrl = currentLargeImg ? currentLargeImg : resCon[0]
            } else {
                const Arr = resCon.filter(item => Array.isArray(item)).flat() || []
                const ArrStr = resCon.filter(item => typeof (item) === 'string').flat() || []
                fileUrl = Arr.length > 0 ? Arr[0] : ArrStr[0]
            }
        } else if (activeModelType === 10) {
            fileUrl = Array.isArray(resCon) && resCon.length > 0 && resCon[0].file ? resCon[0].file : resCon
        } else if (activeModelType === 3 || activeModelType === 5 || activeModelType === 9) {
            fileUrl = Array.isArray(resCon) && resCon.length > 0 ? resCon[0] : resCon ? resCon : ''
        }

        const fileName = isBase64(fileUrl) ? 'New Generated.png' : fileUrl ? fileUrl.substring(fileUrl.lastIndexOf('/') + 1) : 'Generated File';

        fetch(fileUrl)
            .then(response => response.blob())
            .then(blob => {
                const url = window.URL.createObjectURL(new Blob([blob]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', fileName);
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            })
            .catch(error => console.error('Error downloading media:', error));


    }
    useEffect(() => {
        dealCode(currentExample, dataArr)

    }, [currentExample, currentApiKey, dataArr]);

    useEffect(() => {
        if (isLogin) {
            queryApiKeys()
        } else {
            setCurrentApiKey('')
        }
    }, [isLogin]);

    useEffect(() => {
        fetchData()
    }, [])
    return (
        <div className="playground_built">
            <Row className='built_top' justify="space-between" align='center' >
                <Col span={12} className="built_top_left" >
                    <div className="built_top_left">
                        <div >
                            <span className='model_name'>{dataArr?.tit}</span>
                        </div>
                        <div className='tags'>
                            {
                                dataArr && dataArr?.tags && dataArr?.tags.map((item, index) => {
                                    return (
                                        <span key={index} className='tag'>{item}</span>
                                    )
                                })
                            }
                            {/* <span className='tag'>image to image</span>
                            <span className='tag'>Stable Diffusion XL</span> */}
                            <span className='tag'>{dataArr?.access_status === 1 ? "Public" : "Private"}</span>
                            <span className='tag'>＄{dataArr?.price}/{dataArr?.unit}</span>
                            <span className='tag'>{runCount} Runs</span>
                            <span className='tag'>Exec Time: {dataArr?.estimat_exe_time_show} </span>
                            <span className='tag'>Start-up Time: 0 ~ {dataArr?.start_up_time_show} </span>
                        </div>
                    </div>
                </Col>

                <Col span={12}>
                    <div className="built_top_right">
                        <div className="collect" onClick={() => handleCollect(dataArr?.is_favorite)}>
                            {
                                dataArr?.is_favorite !== 0 ? <img src={require('../assets/images/playground/collected.png')} alt="" />
                                    : <img src={require('../assets/images/playground/collect.png')} alt="" />
                            }

                        </div>
                        <Select
                            defaultValue="Examples"
                            options={exampleOptions}
                            onSelect={handleExample}
                            value={currentExample}
                        />
                        <Link to="/playground"><button className="exploreBtn">Explore Other</button></Link>
                    </div>
                </Col>

                <Col span={24}>
                    <div className="tabs">
                        <Tabs defaultActiveKey="1" items={tabItems} onChange={tabChange} ></Tabs>
                    </div>
                </Col>
            </Row>

            {
                currentTab === '1' ?
                    <div className="built_content">
                        <div className="con_left_form">
                            <div className="form_top_con">
                                {dataArr?.form && <Form
                                    form={form}
                                    onFinish={optSubmit}
                                    onReset={optReset}
                                    layout="vertical"
                                    initialValues={initVal}
                                >
                                    {/* 遍历 inputSchema 中的属性，为每个属性生成表单项 */}
                                    {Object.entries(dataArr?.form?.components?.schemas.Input.properties) ? Object.entries(dataArr?.form?.components?.schemas.Input.properties).map(([fieldName, fieldData]) => (
                                        <Form.Item
                                            key={fieldName}
                                            label={fieldData.type === 'boolean' ? '' : fieldData.title ? fieldData.title : fieldName.charAt(0).toUpperCase() + fieldName.slice(1)}
                                            name={fieldName}
                                        // extra={fieldData.description}
                                        >
                                            {getFieldComponent(fieldData, fieldName, form)}
                                        </Form.Item>
                                    )) : null}

                                    <div className="form_bottom_btn">
                                        <Button htmlType='reset'>Reset</Button>
                                        <Button htmlType='submit' type="primary">{loading ? 'Generating...' : 'Generate'}</Button>
                                    </div>
                                </Form>}
                            </div>

                        </div>
                        <div className="con_right_result">
                            <div className="result_con_box">
                                {activeModelType !== 2 && activeModelType !== 4 && activeModelType !== 7 && activeModelType !== 11 && <div className="download" onClick={() => handleDownload()}>
                                    <img src={require('../assets/images/playground/download.png')} alt="" />
                                </div>}
                                {loading ?
                                    (<div className="generating">
                                        <img src={LoadingGif} alt="" />
                                        {countDown >= 1 ? <p>ETA: {`${remainingHours.toString().padStart(2, '0')}:${remainingMinutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`}</p> : <p>Accelerating generation...</p>}
                                    </div>)
                                    :
                                    !loading && resTask && resTask.is_success === false ?
                                        <div className="error_text flex h-full flex-col items-center justify-center">
                                            {resTask?.api_error && resTask?.api_error.message && <div>
                                                <span>{'api_error: ' + resTask?.api_error.message}</span>
                                            </div>}
                                            {resTask?.task_error && <div>
                                                <span>{'task_error: ' + resTask?.task_error}</span>
                                            </div>}
                                            {resTask?.exec_error && <div>
                                                <span>{'exec_error: ' + resTask?.exec_error}</span>
                                            </div>}
                                            {resTask?.output && <div>
                                                <span>{'output: ' + resTask?.output}</span>
                                            </div>}
                                        </div>
                                        :
                                        activeModelType === 1 || activeModelType === 6 || activeModelType === 8 ? (
                                            resCon && Array.isArray(resCon) && resCon.length > 1 ? (
                                                <div className="more_img">
                                                    <div className="more_img_large">
                                                        <img className="img_large" src={currentLargeImg ? currentLargeImg : resCon[0]} alt="" srcset="" />
                                                    </div>
                                                    <div className="more_img_item">
                                                        {
                                                            resCon.map((item, index) => {
                                                                return (
                                                                    <img key={index} onClick={() => setCurrentLargeImg(item)} loading="lazy" className="resImg" alt='' src={item} />
                                                                )
                                                            })
                                                        }
                                                    </div>

                                                </div>
                                            ) : (
                                                <img data-state="closed" loading="lazy" className="resImg" alt='' src={resCon ? resCon : LoadingPng} />
                                            )
                                        ) : activeModelType === 2 ? (
                                            <div className="textToText">
                                                <div className="sender">{textareaVal}</div>
                                                <div className="res_message">
                                                    {resCon && resCon.length > 0 && resCon.join('')}
                                                </div>
                                            </div>
                                        ) : activeModelType === 3 || activeModelType === 5 || activeModelType === 9 ? (
                                            <div className="textToMedia textToVideo">
                                                <Player ref={videoRef}>
                                                    <source src={Array.isArray(resCon) && resCon.length > 0 ? resCon[0] : resCon} />
                                                </Player>
                                            </div>
                                        ) : activeModelType === 10 ? (
                                            <div className="textToMedia">
                                                {resCon && (
                                                    <AudioPlayer audioUrl={Array.isArray(resCon) && resCon.length > 0 && resCon[0].file ? resCon[0].file : resCon} />
                                                )}
                                            </div>
                                        ) : activeModelType === 4 || activeModelType === 7 || activeModelType === 11 ? (
                                            <div className="textToText imgToText">
                                                <div className="res_message">
                                                    {Array.isArray(resCon) && resCon.length > 0 && resCon[0]}
                                                </div>
                                            </div>
                                        ) : null
                                }
                            </div>

                            <div className="result_apiKeyOrCodes">
                                <div className="top_apikey">
                                    <div className="itemLabel">
                                        <span>Export Your Apikey Or Jwt-token As An Environment Variable</span>
                                        <img src={require('../assets/images/playground/question.png')} alt="" />
                                    </div>

                                    <div className="apikey_con">
                                        {
                                            isLogin ? (
                                                <>
                                                    <span className='apikey_text'>{`AIGIC_API_TOKEN="${currentApiKey}"`}</span>
                                                    <img className='cursor-pointer' onClick={() => copyApiKey(currentApiKey)} src={require('../assets/images/playground/copy.png')} alt="" />
                                                </>
                                            ) : (
                                                <a href="#" rel="noreferrer" onClick={() => handleLogin()} className="toLogin">
                                                    Login and get my APIkey{'>>'}
                                                </a>
                                            )
                                        }
                                        {/* <span className='apikey_text'>{`AIGIC_API_TOKEN="${currentApiKey}"`}</span>
                                <img className='cursor-pointer' onClick={() => copyApiKey(currentApiKey)} src={require('../assets/images/playground/copy.png')} alt="" /> */}
                                        {/* <img src={require('../assets/images/playground/change.png')} alt="" />
                                <img src={require('../assets/images/playground/add.png')} alt="" /> */}
                                    </div>
                                </div>

                                <div className="bottom_codes">
                                    <div className="itemLabel">
                                        <span>And Use It In The Following Codes</span>
                                    </div>

                                    <div className="code">
                                        <Tabs defaultActiveKey="Python" onChange={codeTabChange} items={codeTabItems}></Tabs>

                                        <div className="tab_co relative">
                                            {/* lang={'python'} lang={'jsx'} */}
                                            {activeCodeTab === 'Python' && <div><CodeSnippet code={exampleCode?.python_show} /></div>}
                                            {activeCodeTab === 'JavaScript' && <div><CodeSnippet code={exampleCode?.javascript_show} /></div>}
                                            {activeCodeTab === 'HTTP' && <div><CodeSnippet code={exampleCode?.http_show} /></div>}
                                            {activeCodeTab === 'Python-SDK' && <div><CodeSnippet code={exampleCode?.python_sdk_show} /></div>}
                                            {activeCodeTab === 'JavaScript-SDK' && <div><CodeSnippet code={exampleCode?.javascript_sdk_show} /></div>}
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    : currentTab === '2' ?
                        <Playground_API data={dataArr?.form} />
                        :
                        <Playground_Version data={versions} />
            }
        </div>
    )
}

export default Playground