import React, { createContext,useContext, useState } from 'react';
import { doLogin, doLogout } from '../utils/login';


const LoginContext = createContext();

export const LoginProvider = ({ children }) => {
    const [isLogin, setIsLogin] = useState(false);
  
    const login = (callbak) => {
      // 登录逻辑...
        doLogin((res,error) => {
            console.log("doLogin",res,error)
            if (error == null) {
                setIsLogin(true)
            }
            callbak(res,error)
        })
    };
  
    const logout = (callbak) => {
      // 登出逻辑...
      doLogout((res,error) => {
        console.log("doLogout",res,error)
        if (error == null) {
            setIsLogin(false)
        }
        callbak(res,error)
    })
    };
  
    return (
      <LoginContext.Provider value={{ isLogin, setIsLogin,login, logout }}>
        {children}
      </LoginContext.Provider>
    );
  };

  export const useLoginContext = () => useContext(LoginContext);

  
  