import { useLocation } from 'react-router-dom';
import { message } from 'antd'

// import './styles/header.scss'
import './styles/header_dark.scss'
import { useState, useEffect, useCallback } from "react";

import Fetch_ from "../utils/fetch";
import HeaderDropdown from './HeaderDropdown';
import { useLoginContext } from '../context/LoginContext';

const Header = () => {
    const location = useLocation();
    const pathname = location.pathname;
    const host = window.location.host;
    const hostname = window.location.hostname;
    const targetAdminHost = hostname !== 'localhost' ? 'https://console.' + host.replace('www.', '') : 'http://' + host
    const targetDocsHost = hostname !== 'localhost' ? 'https://docs.' + host.replace('www.', '') : 'http://' + host
    const isPlaygroundPage = location.pathname.includes('/playground');
    const isPricing = location.pathname === '/pricing';
    const isDocs = location.pathname === '/docs';
    const isBlog = location.pathname === '/blog';
    const [userInfo, setUserInfo] = useState({});

    const { isLogin, setIsLogin, login, logout } = useLoginContext();

    const menuItems = [
        {
            key: 'userName',
            label: userInfo?.name,
        },
        // {
        //     key: 'accountSetting',
        //     icon: <UserIcon style={{ height: '16px', width: '16px' }} />,
        //     label: 'Account Settings',
        // },
        // {
        //     type: 'divider',
        // },

        // {
        //     key: 'switchColor',
        //     icon: <SwitchColorIcon style={{ height: '16px', width: '16px' }} />,
        //     label: 'Switch Colors',
        // },
        {
            key: 'logout',
            icon: <img src={require('../assets/images/headers/logout.png')} style={{height: '1.25vw', width: '1.25vw'}} alt="" />,
            label: 'Logout',
        },
    ];

    const sendMessage = (token) => {
        const targetWindow = document.getElementById('targetFrame').contentWindow;
        const message = { token };
        targetWindow.postMessage(message, `${targetAdminHost}/receiver`);
    };

    const doLogin = () => {
        login((res, error) => {
            if (error == null) {
                getUserInfo()
                try {
                    const token = localStorage.getItem("token")
                    sendMessage(token)
                } catch (error) {
                    console.log("doLogin sendMessage error", error)
                }
            }
        })
    }

    const doLogout = () => {
        logout((res, error) => {if (error == null) {}})
    }

    const getUserInfo = async () => {
        try {
            const result = await Fetch_("/admin/api/user/userinfo", {
                method: "GET",
            })
            const res = await result.json();
            if (res.code === 200) {
                setUserInfo(res.data)
            } else if (res.code === 401) {
                logout((res, error) => { })
            } else {
                console.log("getUserInfo", res.message)
            };
        } catch (error) {
            console.log("getUserInfo", error.message)
        }
    }

    const onMenuClick = useCallback(
        event => {
            const { key } = event;
            if (key === 'logout') {
                doLogout();
                return;
            }
            if (key === 'accountSetting' || key === 'switchColor') {
                message.warning('This feature is not yet available')
            }
        },
        [],
    );

    useEffect(() => {
        const token = localStorage.getItem("token")
        if (token) {
            setIsLogin(true)
        }
    }, []);

    useEffect(() => {
        const token = localStorage.getItem("token")
        getUserInfo()
        if (isLogin) {
            try {
                sendMessage(token)
            } catch (error) {
                console.log("doLogin sendMessage error", error)
            }
        }
    }, [isLogin]);



    return (
        <header style={pathname !== '/' ? {backgroundColor: '#130337'} : {}}>
            <nav className='flex flex-row items-center justify-between'>
                <a href="/">
                    <div className="logo">
                        <img src={require('../assets/images/logo_.png')} alt="" />
                    </div>
                </a>


                <div className="menu flex flex-row items-center">
                    <ul className='flex flex-row'>
                        <li className={`${isPlaygroundPage && 'active'}`}><a href="/playground">Built with AIGIC</a></li>
                        {/* <li className={`${isPricing && 'active'}`}><a href="#">Pricing</a></li> */}
                        <li className={`${isDocs && 'active'}`}><a href={targetDocsHost} target='_blank'>Docs</a></li>
                        {/* <li className={`${isBlog && 'active'}`}><a href="#">Blog</a></li> */}
                    </ul>

                    <div className="btns">
                        <a href={targetAdminHost} target="_blank" rel="noopener noreferrer">
                            <button className='dashboard'>Dashboard</button>
                            {/* <button className='dashboard dark:bg-gradient-to-b  dark:from-[#D3ADF7] dark:to-[#722ED1] dark:from-0% dark:to-100%'>Dashboard</button> */}
                        </a>
                    </div>

                    {
                        isLogin ? (
                            <div className="ava">
                                <HeaderDropdown
                                    menu={{
                                        selectedKeys: [],
                                        onClick: onMenuClick,
                                        items: menuItems,
                                    }}
                                    placement="bottomRight"
                                    overlayStyle={{
                                        marginTop: '20px',
                                        insetInlineEnd: '3.28vw !important',
                                    }}
                                >
                                    <img src={require('../assets/images/headers/ava.png')} alt="" />
                                    {/* <img src={userInfo && userInfo?.profile_image ? userInfo?.profile_image : require('../assets/images/headers/ava.png')} alt="" /> */}
                                </HeaderDropdown>
                            </div>
                        ) : (
                            <div className="btns">
                                <button className="loginBtn" onClick={doLogin}>Login</button>
                            </div>
                        )
                    }

                    {/* <div className="btns">
                        <Button className="btn-light login" onClick={doLogout}>Logout</Button>
                    </div> */}

                    {/* <div className="lang">
                        <img src={require('../assets/images/headers/lang.png')} alt="" />
                    </div> */}

                </div>

            </nav>
        </header>
    );
};

export default Header;