import React, { useCallback, useRef } from 'react';
import { useWavesurfer } from '@wavesurfer/react'

const formatTime = (seconds) => [seconds / 60, seconds % 60].map((v) => `0${Math.floor(v)}`.slice(-2)).join(':')

const AudioPlayer = ({ audioUrl }) => {
    const containerRef = useRef(null)

    const { wavesurfer, isPlaying, currentTime } = useWavesurfer({
        container: containerRef,
        height: 150,
        waveColor: '#fff',
        progressColor: '#722ED1',
        url: audioUrl,
    })

    const onPlayPause = useCallback(() => {
        try {
            wavesurfer && wavesurfer.playPause()
        } catch (error) {
            console.log(error)
        }

    }, [wavesurfer])

    return (
        <>
            {(
                <>
                    <div
                        className="playIcon"
                        onClick={() => {
                            if (!wavesurfer?.getDuration()) {
                                return
                            }
                            onPlayPause()
                        }}>
                        {!isPlaying ? <img className="handleBtn" src={require('../assets/images/home/play-fill.png')} alt="" /> : <img className="handleBtn" src={require('../assets/images/home/pause.png')} alt="" />}
                    </div>
                    <div className="times">{formatTime(currentTime)}</div>
                    <div className="process">
                        <div ref={containerRef}></div>
                        {!wavesurfer?.getDuration() && <div className="process_null"></div>}
                    </div>
                    <div className="times">{formatTime(wavesurfer?.getDuration())}</div>
                </>
            )}
        </>
    );
};

export default AudioPlayer;
