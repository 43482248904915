// CodeSnippet.js
import React from 'react';
import { message } from 'antd';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { CopyToClipboard } from 'react-copy-to-clipboard';

const CodeSnippet = ({ code, isHome, lang }) => {
    const handleCopy = (e) => {
        navigator.clipboard.writeText(e)
            .then(() => {
                message.success('Copied');
            })
            .catch(() => {
                message.error('Copied Failed');
            });
    };

    return (
        <div className='overflow-auto' style={!isHome ? { height: '23.02vw' } : {}}>
            <CopyToClipboard text={code} onCopy={handleCopy}>
                <img className='absolute top-2 right-4 cursor-pointer' style={{height: '1.25vw', width: '1.25vw'}} src={require('../assets/images/playground/copy.png')} alt="" />
            </CopyToClipboard>
            <SyntaxHighlighter language={lang} customStyle={{ margin: 0, height: '100%', backgroundColor: '#22075E', fontSize: '.83vw', fontWeight: 400, color: '#fff',  textShadow: 'none', fontFamily: 'OpenSans-Regular' }}>
                {code && code.length ? code : "Stay tuned, we'll be here soon"}
            </SyntaxHighlighter>
        </div>
    );
};

export default CodeSnippet;
