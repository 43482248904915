import { useEffect } from "react";

const ReceiveMessage = () => {
    const handleMessage = (event) => {
        const data = event.data;
        if (data.token) localStorage.setItem('token', data.token)
        // 验证消息来源
        if (event.origin !== window.location.origin) {
            console.log('(Web)Received message from invalid origin:', event.origin, window.location.origin);
            return;
        }
    
        // 处理收到的消息
        console.log('(Web)Received message:', event.data);
    };

    
    useEffect(()=> {
        window.addEventListener('message', handleMessage);
    }, [])

    return <div></div>
}

export default ReceiveMessage;