import { useState, useEffect } from "react";
import { Timeline } from 'antd';
import '../styles/playground_version.scss'
import moment from 'moment'

const Playground_Version = ({ data }) => {
    const [versions, setVersions] = useState([]);

    useEffect(() => {
        init()
    }, [data]);

    function compareVersion(v1, v2) {
        // 将版本号字符串按 "." 分割为数组
        const v1Parts = v1.split(".");
        const v2Parts = v2.split(".");

        // 比较每个部分的数字大小
        for (let i = 0; i < Math.max(v1Parts.length, v2Parts.length); i++) {
            const num1 = parseInt(v1Parts[i] || 0);
            const num2 = parseInt(v2Parts[i] || 0);

            if (num1 < num2) {
                return 1;
            } else if (num1 > num2) {
                return -1;
            }
        }

        // 版本号相同
        return 0;
    }

    async function init() {
        console.log("init", data);
        let keys = Object.keys(data);
        keys = keys.sort((a, b) => compareVersion(a, b));
        let versions = []
        console.log("init keys", keys)
        keys.map((key, index) => {
            let version = data[key]
            console.log("init version", version)
            let created_time = (version && version.length) ? version[0].created_time : ''
            created_time = moment(created_time).format("YYYY-MM-DD HH:mm:ss")
            let version_show = {
                label: created_time
            }

            let version_code = <p key={key + index}><span style={{ marginRight: '10px', fontWeight: 'bold' }}>Version:</span> {key}</p>


            let hashArr = version.map(item => {
                return <p key={item.os + index}><span style={{ marginRight: '10px', fontWeight: 'bold' }}>{item.os}:</span>{item.hash}</p>
            })


            // let descArr = version.map((item, index) => {
            //     return item.desc ? <p key={item.desc+index}>{item.desc}</p> : null
            // })
            let Desc = <p key={"desc" + index}>
                <span style={{ marginRight: '10px', fontWeight: 'bold' }}>Change log:</span>
                {
                    version && version.length > 0 && version.map((item, index) => {
                        return item.desc ? <p key={item.desc + index}>{item.desc}</p> : null
                    })
                }
            </p>

            version_show.children = [version_code, ...hashArr, Desc]
            versions.push(version_show)
        })
        setVersions(versions)
        console.log("init versions", versions)
    }

    return (
        <div className="built_content">
            <div className="version_content">
                <div className="version_timeline">
                    <Timeline
                        mode={'left'}
                        items={versions}
                    />
                </div>

            </div>

        </div>
    )
}

export default Playground_Version;