
import { Web3Auth } from "@web3auth/modal";
import Web3 from "web3";
import { WALLET_ADAPTERS } from "@web3auth/base";
import { getPublicCompressed } from "@toruslabs/eccrypto";


import Fetch_ from "../utils/fetch";
import React from "react";

const loginContext = React.createContext();

const clientId = 'BHFvULiKBqRN0Em-14M-bDO2eAvHL4F19hLxR4s7dkksiIx8cIk5VlZvJeJbf8WwT5bX3FMhil45HjVbmd1Wmk4'

const doLogout = async (callback) => {
    try {
        await Fetch_('/admin/api/user/logout', { method: "POST", })
    } catch (error) {
        console.log(error)
        callback(null, error.message)
    }
    try {
        localStorage.removeItem('token');
        const web3auth = initWeb3auth();
        await web3auth.initModal();
        if (web3auth.connected) {
            await web3auth.logout();
        }
        callback(200, null)
    } catch (error) {
        console.log(error)
        callback(null, error.message)
    }
}

const doLogin = async (callback) => {
    const web3auth = initWeb3auth();
    const temp = {
        modalConfig: {
            [WALLET_ADAPTERS.OPENLOGIN]: {
                label: "openlogin",
                loginMethods: {
                    facebook: {
                        name: "facebook",
                        showOnModal: false,
                    },
                    twitter: {
                        // it will hide the facebook option from the Web3Auth modal.
                        name: "twitter",
                        showOnModal: false,
                    },
                    reddit: {
                        // it will hide the facebook option from the Web3Auth modal.
                        name: "reddit",
                        showOnModal: false,
                    },
                    discord: {
                        // it will hide the facebook option from the Web3Auth modal.
                        name: "discord",
                        showOnModal: false,
                    },
                    twitch: {
                        // it will hide the facebook option from the Web3Auth modal.
                        name: "twitch",
                        showOnModal: false,
                    },
                    apple: {
                        // it will hide the facebook option from the Web3Auth modal.
                        name: "apple",
                        showOnModal: false,
                    },
                    line: {
                        // it will hide the facebook option from the Web3Auth modal.
                        name: "line",
                        showOnModal: false,
                    },
                    kakao: {
                        // it will hide the facebook option from the Web3Auth modal.
                        name: "kakao",
                        showOnModal: false,
                    },
                    weibo: {
                        // it will hide the facebook option from the Web3Auth modal.
                        name: "weibo",
                        showOnModal: false,
                    },
                    wechat: {
                        // it will hide the facebook option from the Web3Auth modal.
                        name: "wechat",
                        showOnModal: false,
                    },
                    sms_passwordless: {
                        // it will hide the facebook option from the Web3Auth modal.
                        name: "sms_passwordless",
                        showOnModal: false,
                    },
                    farcaster: {
                        name: 'farcaster',
                        showOnModal: false,
                    }

                },
                // setting it to false will hide all social login methods from modal.
                showOnModal: true,
            },
            [WALLET_ADAPTERS.WALLET_CONNECT_V2]: {
                label: "wallet_connect",
                showOnModal: false,
            },
            // Disable Metamask
            [WALLET_ADAPTERS.METAMASK]: {
                label: "metamask",
                showOnModal: false,
            },
            [WALLET_ADAPTERS.COINBASE]: {
                label: "coinbase",
                showOnModal: false,
            },
            [WALLET_ADAPTERS.TORUS_SOLANA]: {
                label: "torus-solana",
                showOnModal: false,
            },
            [WALLET_ADAPTERS.PHANTOM]: {
                label: "phantom",
                showOnModal: false,
            },
            [WALLET_ADAPTERS.SOLFLARE]: {
                label: "solflare",
                showOnModal: false,
            },
            [WALLET_ADAPTERS.SLOPE]: {
                label: "slope",
                showOnModal: false,
            },
            [WALLET_ADAPTERS.TORUS_EVM]: {
                label: "torus-evm",
                showOnModal: false,
            },
        },
    }
    await web3auth.initModal(temp);
    handleLogin(web3auth, callback)
}

const handleLogin = async (web3auth, callback) => {
    if (!web3auth) {
        callback(null, "web3auth not initialized yet")
        return;
    }
    try {
        let provider = await web3auth.connect();

        if (web3auth.connected) {
            const user = await web3auth.getUserInfo();

            const app_scoped_privkey = await provider.request({
                method: "eth_private_key", // use "private_key" for other non-evm chains
            });
            const app_pub_key = getPublicCompressed(
                Buffer.from(app_scoped_privkey.padStart(64, "0"), "hex")
            ).toString("hex");

            const web3 = new Web3(provider);
            const address = await web3.eth.getAccounts();

            let requestUser = {
                user: {
                    mail: user.email,
                    chain_account: (address && address.length && address[0]) || "",
                    register_from_desc: user.typeOfLogin,
                    name: user.name,
                    type: 0,
                },
                id_token: user.idToken,
                web3auth_public_key: app_pub_key,
            };

            loginRequest(requestUser, web3auth, callback);
        }
    } catch (error) {
        console.log("login", error.message);
        callback(null, error)
    }
};

const loginRequest = async (user, web3auth, callback) => {
    try {
        const result = await Fetch_("/admin/api/user/login", {
            method: "POST",
            body: JSON.stringify(user)
        })
        const response = result
        const res = await result.json();
        if (res.code === 200) {
            callback(200, null)
        } else {
            callback(null, res.err)
            await web3auth.connect();
        };
    } catch (error) {
        callback(null, error.message)
        await web3auth.connect();
    }
};

const initWeb3auth = () => {
    const web3auth = new Web3Auth({
        uiConfig: {
            appName: "AIGIC",
            theme: {
                primary: "#722ed1",
            },
            mode: "dark",
            appUrl: "",
            appLogo: '/favicon.ico',
            logoLight: '/favicon.ico',
            logoDark: '/favicon.ico',
            useLogoLoader: false,
            defaultLanguage: "en",
            loginGridCol: 2,
            loginMethodsOrder: ["github", "google", "linkedin", "email_passwordless"],
        },
        chainConfig: {
            chainNamespace: "eip155",
            chainId: "0x1",
            rpcTarget: "https://rpc.ankr.com/eth",
            displayName: "Ethereum Mainnet",
            blockExplorer: "https://goerli.etherscan.io",
            ticker: "ETH",
            tickerName: "Ethereum",
        },
        clientId,
        web3AuthNetwork: "sapphire_devnet", // Web3Auth Network
    });

    return web3auth;
}

export {
    loginContext,
    doLogin,
    doLogout
}