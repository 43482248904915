// routes.js

import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Playground from './pages/Playground_list';
import PlaygroundDetail from './pages/Playground';
import Home from './pages/Home';
import ReceiveMessage from './pages/ReceiveMessage';

const AppRoutes = () => {
  return (
    <Routes>
      <Route
        path="/"
        element={<Home />}
      />
      <Route
        path="/playground"
        element={<Playground />}
      />

      <Route
        path="/playground/:key"
        element={<PlaygroundDetail />}
      />

      <Route
        path="/receive-message"
        element={<ReceiveMessage />}
      />

    </Routes>
  );
};

export default AppRoutes;
