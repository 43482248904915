import { useState ,useEffect} from "react";
import { Space, Switch, Table } from 'antd';
import { far } from "react-syntax-highlighter/dist/esm/styles/hljs";

const Playground_API = ({data}) => {
    const [currentNav, setCurrentNav] = useState(1);
    
    const headercolumns = [
        {
            title: 'Name',
            dataIndex: 'name',
        },
        {
            title: 'Value',
            dataIndex: 'value',
            // width: '40%',
        },
        {
            title: 'Required',
            dataIndex: 'required_show',
            // width: '30%',
        },
        {
            title: 'Required',
            dataIndex: 'required',
            hideInTable:true,
            // width: '30%',
        },
        {
            title: 'Example',
            dataIndex: 'example',
            // width: '30%',
        },
        {
            title: 'Remark',
            dataIndex: 'remark',
            // width: '30%',
        }
    ];

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
        },
        {
            title: 'Type',
            dataIndex: 'type',
            // width: '40%',
        },
        {
            title: 'Required',
            dataIndex: 'required',
            // width: '30%',
        },
        {
            title: 'Default',
            dataIndex: 'default',
            // width: '30%',
        },
        {
            title: 'Remark',
            dataIndex: 'remark',
            // width: '30%',
        },
        {
            title: 'Other',
            dataIndex: 'other',
            // width: '30%',
        },
    ];
    const testdata = [
        {
            key: 1,
            name: 'John Brown sr.',
            age: 60,
            address: 'New York No. 1 Lake Park',
            children: [
                {
                    key: 11,
                    name: 'John Brown',
                    age: 42,
                    address: 'New York No. 2 Lake Park',
                },
                {
                    key: 12,
                    name: 'John Brown jr.',
                    age: 30,
                    address: 'New York No. 3 Lake Park',
                    children: [
                        {
                            key: 121,
                            name: 'Jimmy Brown',
                            age: 16,
                            address: 'New York No. 3 Lake Park',
                        },
                    ],
                },
                {
                    key: 13,
                    name: 'Jim Green sr.',
                    age: 72,
                    address: 'London No. 1 Lake Park',
                    children: [
                        {
                            key: 131,
                            name: 'Jim Green',
                            age: 42,
                            address: 'London No. 2 Lake Park',
                            children: [
                                {
                                    key: 1311,
                                    name: 'Jim Green jr.',
                                    age: 25,
                                    address: 'London No. 3 Lake Park',
                                },
                                {
                                    key: 1312,
                                    name: 'Jimmy Green sr.',
                                    age: 18,
                                    address: 'London No. 4 Lake Park',
                                },
                            ],
                        },
                    ],
                },
            ],
        },
        {
            key: 2,
            name: 'Joe Black',
            age: 32,
            address: 'Sydney No. 1 Lake Park',
        },
    ];

    const [headers, setHeaders] = useState([]);
    const [body, setBody] = useState([]);
    const [response, setResponse] = useState([]);
    const [errorCode, setErrorCode] = useState([]);
    const [interfaceData, setInterfaceData] = useState({});
    const [apiPtah, setApiPtah] = useState('');
    const [basePath, setBasePath] = useState('');

    


    useEffect(() => {
        init()
    }, []);


    async function init() {
        if (typeof data == 'string') {
            data = JSON.parse(data)
        }
        console.log("Playground_API",data)
        setBasePath(data.info.basePath)
        let paths = data && data.paths ? Object.keys(data.paths) : []
        let interfaceData = null
        console.log("Playground_API paths",paths)
        if (paths && paths.length) {
            paths.map((item) => {
                interfaceData = data.paths[item].post
                if (interfaceData && interfaceData.summary && interfaceData.summary === 'Predict') {
                    setApiPtah(item)
                    setInterfaceData(interfaceData)
                    return false
                }
            })
        }
        if (interfaceData && interfaceData.parameters && interfaceData.parameters.length) {
            let content_types = []
            if (interfaceData && interfaceData.requestBody && interfaceData.requestBody.content) {
                content_types = Object.keys(interfaceData.requestBody.content)
            }
            let headsers = []
            content_types.map((item) => {
                let header = {
                    name:"Content-Type",
                    required:true,
                    required_show:"Yes",
                    value:item,
                    example:item.example,
                    remark:item.remark,
                }
                headsers.push(header)
            })
            interfaceData.parameters.map((item) => {
                if (item.in === 'header') {
                    let header = {
                        name:item.name,
                        required:item.required,
                        required_show: item.required ? "Yes" : "No",
                        value:item.value,
                        example:item.example,
                        remark:item.remark,
                    }
                    headsers.push(header)
                }
            })
            setHeaders(headsers)
        }

        if (interfaceData && interfaceData.requestBody && interfaceData.requestBody.content) {
            let content_types = []
            if (interfaceData && interfaceData.requestBody && interfaceData.requestBody.content) {
                content_types = Object.keys(interfaceData.requestBody.content)
            }
            let content = null
            if (content_types && content_types.length) {
                content = interfaceData.requestBody.content[content_types[0]]
            }
            let request = null
            if (content) {
                let ref = content.schema['$ref']
                request = data['components.schemas.PredictionRequest']
            }
            console.log("init",request)
            let bodys = []

            // interfaceData.parameters.map((item) => {
            //     if (item.in === 'header') {
            //         let header = {
            //             name:item.name,
            //             required:item.required,
            //             required_show: item.required ? "Yes" : "No",
            //             value:item.value,
            //             example:item.example,
            //             remark:item.remark,
            //         }
            //         headsers.push(header)
            //     }
            // })
            // setHeaders(headsers)
        }
    }

    return (
        <div className="built_content">

            <div className="api_content">
                <div className="leftNav">
                    <ul>
                        <li className={`${currentNav === 1 && 'li_active'}`} onClick={() => setCurrentNav(1)}><a href="#Basic">Basic Information</a></li>
                        <li className={`${currentNav === 2 && 'li_active'}`} onClick={() => setCurrentNav(2)}><a href="#Headers">Headers</a></li>
                        <li className={`${currentNav === 3 && 'li_active'}`} onClick={() => setCurrentNav(3)}><a href="#Body">Body</a></li>
                        <li className={`${currentNav === 4 && 'li_active'}`} onClick={() => setCurrentNav(4)}><a href="#Response">Response</a></li>
                        <li className={`${currentNav === 5 && 'li_active'}`} onClick={() => setCurrentNav(5)}><a href="#ErrorCode">ErrorCode</a></li>
                    </ul>
                </div>

                <div className="rightContent">
                    <p className="module_tit" id="Basic">Basic Information</p>
                    <div className="info">
                        <div className="info_item">
                            <p className="item_label">Interface Name：</p>
                            <p className="item_con">{interfaceData.summary}</p>
                        </div>
                        <div className="info_item">
                            <p className="item_label">Interface Path：</p>
                            <p className="item_con">{`${basePath}${apiPtah}`}</p>
                        </div>
                        <div className="info_item">
                            <p className="item_label">Multiline Text Title：</p>
                            <p className="item_con">{interfaceData.description}</p>
                            {/* <p className="item_con">The following examples all expect your Replicate access token to be available from the command line. Because tokens are secrets, they should not be in your code. They should instead be stored in environment variables. Replicate clients look for the REPLICATE_API_TOKEN environment variable and use it if available.</p> */}
                        </div>
                    </div>


                    <p className="module_tit" id="Headers">Headers</p>
                    <div className="info">
                        <div className="info_item">
                            <p className="item_label">Multiline Text Title：</p>
                            <Table
                                    className="item_table"
                                    columns={headercolumns}
                                    rowSelection={false}
                                    dataSource={headers}
                                    pagination={false}
                                    bordered={false}
                                />
                        </div>
                    </div>

                    <p className="module_tit" id="Body">Body</p>
                    <div className="info">
                        <div className="info_item">
                            <p className="item_label">Multiline Text Title：</p>
                            <Table
                                    className="item_table"
                                    columns={columns}
                                    rowSelection={false}
                                    dataSource={testdata}
                                    pagination={false}
                                    bordered={false}
                                />
                        </div>
                    </div>

                    <p className="module_tit" id="Response">Response</p>
                    <div className="info">
                        <div className="info_item">
                            <p className="item_label">Multiline Text Title：</p>
                            <Table
                                    className="item_table"
                                    columns={columns}
                                    rowSelection={false}
                                    dataSource={testdata}
                                    pagination={false}
                                    bordered={false}
                                />
                        </div>
                    </div>

                    <p className="module_tit" id="ErrorCode">ErrorCode</p>
                    <div className="info">
                        <div className="info_item">
                            <p className="item_label">Multiline Text Title：</p>
                            <Table
                                    className="item_table"
                                    columns={columns}
                                    rowSelection={false}
                                    dataSource={testdata}
                                    pagination={false}
                                    bordered={false}
                                />
                        </div>
                    </div>

                </div>
            </div>

        </div>
    )
}

export default Playground_API;