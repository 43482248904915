export default async function Fetch_(url, options = {}) {

    try {

        // 从 localStorage 中获取 token，你也可以从其他地方获取
        const token = localStorage.getItem('token');

        // 设置请求头部，将 token 添加到 Authorization 头中
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': token || null,
            ...options.headers, // 可以合并传入的 headers
        };
        const fetchOptions = {
            ...options,
            headers,
        }
        // 发送请求
        const response = await fetch(url, fetchOptions);
        // 检查是否请求成功
        if (!response.ok) {
            if(response.status === 401){
                localStorage.removeItem('token');
                // logout()
                // setIsLogin(false)
            }
            // throw new Error(`Request failed with status ${response.status}`);
        }

        // 从 response headers 中获取 token
        const resToken = response.headers.get('Authorization');

        // 将 token 存储到 localStorage 中
        if (resToken) {
            localStorage.setItem('token', resToken);
        }

        // 解析响应体并返回
        return response;
    } catch (error) {
        console.error('Error:', error);
        throw error;
    }
}
